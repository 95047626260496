@import url('./assets/global.css');

body {
  color: var(--color);
  font-size: 1.1rem;
  font-family: var(--font-family) !important;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a:hover {
  text-decoration: none !important;
}
