/* #### Generated By: http://www.cufonfonts.com #### */

@font-face {
  font-family: 'Tisa Sans Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Regular'), url('Tisa Sans Pro Regular.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Italic'), url('Tisa Sans Pro Italic.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Extra Light';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Extra Light'), url('Tisa Sans Pro ExtraLight.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Extra Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Extra Light Italic'), url('Tisa Sans Pro ExtraLight Italic.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Light';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Light'), url('Tisa Sans Pro Light.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Light Italic'), url('Tisa Sans Pro Light Italic.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Medium'), url('Tisa Sans Pro Medium.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Medium Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Medium Italic'), url('Tisa Sans Pro Medium Italic.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Bold'), url('Tisa Sans Pro Bold.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Bold Italic'), url('Tisa Sans Pro Bold Italic.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Extra Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Extra Bold'), url('Tisa Sans Pro ExtraBold.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Extra Bold Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Extra Bold Italic'), url('Tisa Sans Pro ExtraBold Italic.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Black Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Black Italic'), url('Tisa Sans Pro Black Italic.woff') format('woff');
  font-display: swap;
}


@font-face {
  font-family: 'Tisa Sans Pro Black';
  font-style: normal;
  font-weight: normal;
  src: local('Tisa Sans Pro Black'), url('Tisa Sans Pro Black.woff') format('woff');
  font-display: swap;
}